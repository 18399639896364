import request from '@/plugins/axios'


// 评论列表
export const apiCommunityCommentLists = (params: any) => request.get('/community.community_comment/lists', { params })

// 添加评论
export const apiCommunityCommentAdd = (params: any) => request.post('/community.community_comment/add', params)

// 编辑评论
export const apiCommunityCommentEdit = (params: any) => request.post('/community.community_comment/edit', params)

// 删除评论
export const apiCommunityCommentDelete = (params: any) => request.post('/community.community_comment/delete', params)

// 评论详情
export const apiCommunityCommentDetail = (params: any) => request.get('/community.community_comment/detail', { params })

export const apiCommunityCommentSwitchStatus = (params: any) => request.post('/community.community_comment/status', params)