
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import DatePicker from "@/components/date-picker.vue";
import { apiCommunityCommentLists, apiCommunityCommentDelete, apiCommunityCommentSwitchStatus } from '@/api/community/community_comment'

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    DatePicker,
  },
})


export default class CommunityCommentList extends Vue {
  /** S Data **/

  apiCommunityCommentLists = apiCommunityCommentLists;

  pager: RequestPaging = new RequestPaging();

  queryParams: Object = {
    status: "",
    shop_info: "",
    start_time: "",
    end_time: "",
  };
  /** E Data **/

  /** S Methods **/

  // 获取商城列表
  getLists(): void {
    this.pager.request({
      callback: apiCommunityCommentLists,
      params: {
        ...this.queryParams,
      },
    });
  }

  // 修改商城状态
  async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
    await apiCommunityCommentSwitchStatus({ id, status: status });
    this.getLists();
  }

  // 删除商城
  async onDelete(row: any): Promise<void> {
    await apiCommunityCommentDelete({ id: row.id });
    this.getLists();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.queryParams).map((key) => {
      this.$set(this.queryParams, key, "");
    });
    this.getLists();
  }

  // 去编辑商城
  toEdit(id: number | any): void {
    this.$router.push({
      path: "/shop/set_meal_edit",
      query: {
        id: id,
      },
    });
  }

  /** E Methods **/

  /** S ods **/
  created() {
    this.getLists();
  }
}
